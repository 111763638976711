import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive Assessments",
  },
  "header": {
    "title": <><span>Interactive</span> Assessments</>,
    "description": "Leverage AI to create tailored assessments that help customers evaluate your services and solutions.",
    "app": "/apps/61f9bb9f833873193c2a58c6"
  },
  "main": [
    {
      "title": "Marketing Services Assessment",
      "description": "Create marketing assessments to help potential customers evaluate your marketing services and outcomes.",
      "app": "/apps/60f0760040cc482d057c6ade"
    },
    {
      "title": "Climate Readiness Assessment",
      "description": "Develop climate readiness assessments to promote your climate strategy consulting services.",
      "app": "/apps/5fe52bf16430cb1c6913b89e"
    },
    {
      "title": "DEI Business Assessment",
      "description": "Design DEI assessments to assist businesses in determining their need for DEI strategy consulting.",
      "app": "/apps/60783f693a327c429d77ed33"
    },
    {
      "title": "Technology Maturity Assessment",
      "description": "Offer technology maturity assessments to guide members in evaluating their technology maturity with actionable insights.",
      "app": "/apps/66d44d6395166bc0e114a183"
    },
    {
      "title": "Risk Management Assessment",
      "description": "Provide risk management assessments to help prospects understand their risks and explore your risk consulting services.",
      "app": "/apps/615c4d682d01c223c661b988"
    }
  ],
  "caseStudies": [
    "the-acacia-company-dei-assessments",
    "within-performance-branding-assessment",
    "strategy-dna-climate-readiness-assessment"
  ]
}

const Assessments = () => {
  return (
    <Template content={content} />
  )
};
export default Assessments;
