import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Choose Your Own Adventure Interactive E-Books",
  },
  "header": {
    "title": <>Choose Your Own Adventure <span>Interactive</span> E-Books</>,
    "description": "Create immersive, personalized stories with our No-Code Interactive Content platform.",
    "app": "/apps/6352958519f78e73f1100cdc"
  },
  "main": [
    {
      "title": "Choose your own adventure books",
      "description": "The original static content and the converted interactive content",
      "apps": [
        "/apps/66d3565b95166bc0e1149859"],
      "cta": ""
    },
  ],
}

const EBooks = () => {
  return (
    <Template content={content} />
  )
};
export default EBooks;
