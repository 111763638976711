import React from "react";
import Template from "../../components/Template";
import Sitemap from '../../components/Navbar/sitemap.json';

const content = {
  "meta": {
    "title": "Interactive Content is the Future",
  },
  "header": {
    "title": <><span>Interactive Content</span> Collections</>,
    "description": <>Embed collections of interactive resources on your website.</>,
    "app": "/collections/64bc3170fd1d5c1bdba61eb6"
  },
  "main": [
    {
      "title": "Your Articles",
      "app": "/collections/674e2f4ee65f10da50f2cbb6"
    },
    {
      "title": "Your Case Studies",
      "app": "/collections/674e2f6de65f10da50f2cbd7"
    },
    {
      "title": "Your News",
      "app": "/collections/674e2faae65f10da50f2cc22"
    },
    {
      "title": "Your Resources",
      "app": "/collections/674ef192453a6702ff950b0b"
    },
    {
      "title": "Your Directories",
      "app": "/collections/674f2ca8e65f10da50f31476"
    },
  ],
}

const Collections = () => {
  return (
    <Template content={content} />
  )
};
export default Collections;
