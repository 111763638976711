import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";

const FullScreenCollection = ({ title, app }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Navbar />
      <main style={{ width: 'calc(90%)', margin: '8rem calc(5%) 0', height: 'calc(100vh - 9rem)' }}>
        <iframe style={{ width: '100%', height: '100%' }} src={`${window.location.hostname.endsWith('localhost') ? 'https://localhost' : 'https://logictry.com'}/collections/${app}?allowfullscreen=false&autoresize=false`}></iframe>
      </main>
    </>
  )
};
export default FullScreenCollection;
