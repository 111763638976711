import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive Repair Manuals",
  },
  "header": {
    "title": <><span>Interactive</span> Repair Manuals</>,
    "description": "Use AI to build product recommendation guides",
    "app": "/apps/5f6cecf1a96d9b13100b876f",
  },
  "main": [
    {
      "title": "Custom Branded Directories",
      "description": "Fiesta curated a directory of austin accelerators and incubators",
      "app": "/apps/62fcf9c4bfe1ab0e46b4d830"
    },
    {
      "title": "Custom Branded Directories",
      "description": "Fiesta curated a directory of austin accelerators and incubators",
      "app": "/apps/5f60e79363713860c4cd93a7"
    },
    {
      "title": "Custom Branded Directories",
      "description": "Fiesta curated a directory of austin accelerators and incubators",
      "app": "/apps/6502238774dc8d177d6e6920"
    },
  ],
}

const RepairManuals = () => {
  return (
    <Template content={content} />
  )
};
export default RepairManuals;
