import React from "react";
import Template from "../../components/Template";
import Sitemap from '../../components/Navbar/sitemap.json';

const content = {
  "meta": {
    "title": "The Future of Marketing is Interactive Content",
  },
  "header": {
    "title": <>The Future of Marketing is <span>Interactive</span> Content</>,
    "description": "Boost engagement and conversion with interactive content",
    "youtube": "https://www.youtube.com/embed/YOvuO7TMILE"
  },
  "main": [
    {
      "title": "Fun Quizzes and Assessments",
      "description": <><span>52.6%</span> higher engagement rates with interactive content compared to static content.</>,
      "link": Sitemap.Quizzes,
      "app": "/apps/66d86b9a69fc1595efa608d8"
    },
    {
      "title": "Interactive Buying Guides",
      "description": <><span>70%</span> increased buyer conversion with interactive content compared to static content.</>,
      "link": Sitemap.BuyingGuides,
      "app": "/apps/6578b9d36837722e73ead772"
    },
    {
      "title": "Smart FAQs and Chatbots",
      "description": "Create powerful chatbots to answer questions based on the training and answers you provide.",
      "link": Sitemap.Chatbots,
      "app": "/apps/66b25fede559423de19e7020?showchatbot=true",
    },
    {
      "title": "Interactive Handbooks, Playbooks, and SOP(s)",
      "description": <><span>51%</span> of B2B buyers say interactive content is helpful when tackling business challenges.</>,
      "link": Sitemap.SalesPlaybooks,
      "app": "/apps/64ce7bc129bc980dbe5a16b9",
    },
    // {
    //   "title": "Grab Attention",
    //   "description": <><span>81%</span> of marketers agree that interactive content is more effective at grabbing people's attention than static content.</>,
    //   "youtube": "https://www.youtube.com/embed/YOvuO7TMILE"
    // },
    // {
    //   "title": <>Boost Engagement</>,
    //   "description": <><span>52.6%</span> higher engagement rates with interactive content compared to static content</>,
    //   "youtube": "https://www.youtube.com/embed/YOvuO7TMILE"
    // },
    // {
    //   "title": "Repeat Visitors",
    //   "description": <><span>77%</span> of marketers agree that interactive content results in repeat visitors and multiple exposures.</>,
    //   "youtube": "https://www.youtube.com/embed/YOvuO7TMILE"
    // },
  ],
}

const MarketingTeams = () => {
  return (
    <Template content={content} />
  )
};
export default MarketingTeams;
