import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive Document Generators"
  },
  "header": {
    "title": <><span>Interactive</span> Document Generators</>,
    "description": "Create legal and other documents efficiently with AI-Enabled document generators.",
    "app": "/apps/6386223025ea03095f80173a"
  },
  "main": [
    {
      "title": "FAST Agreement",
      "description": "Generate FAST agreements with ease using our document generator, modeled after the Founder Institute's template.",
      "app": "/apps/63a0985c9271c02063256a44"
    },
    {
      "title": "Ease Agreement",
      "description": "Create Ease agreements effortlessly with our document generator, inspired by the Founder Institute's EASE template.",
      "app": "/apps/63a0a6ce9271c02063256a45"
    },
    {
      "title": "Mutual Non-Disclosure",
      "description": "Produce Mutual Non-Disclosure Agreements quickly with our generator, based on the CooleyGo NDA template.",
      "app": "/apps/62c072d6f516b5291aae38d8"
    }
  ]
}

const DocumentGenerators = () => {
  return (
    <Template content={content} />
  )
};
export default DocumentGenerators;
