import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Choose Your Own Adventure Interactive E-Books",
  },
  "header": {
    "title": <>Choose Your Own Adventure <span>Interactive</span> E-Books</>,
    "description": "Create immersive, personalized stories with our No-Code Interactive Content platform.",
    "app": "/apps/6352958519f78e73f1100cdc"
  },
  "main": [
    {
      "title": <>Outwitting Fear</>,
      "app": "/apps/5ebabbfcae527b799ba7f9d6"
    },
    {
      "title": <>Sage Guideflows</>,
      "app": "/apps/5eaf2ef3d0d0442cb6bcab7e"
    },
    {
      "title": <>3-Step Mindset Reset</>,
      "app": "/apps/5ed97e190e5e7f2733a5dce5"
    },
    {
      "title": <>Founders Academy Essentials Workbook</>,
      "app": "/apps/5e95083a7bca6007bbaa4a72"
    },
    {
      "title": <>DivInc Mentor Meetups</>,
      "app": "/apps/5e9620847bca6007bbaa4a73"
    },
    {
      "title": <>Humanistic Marketing Amidst a Crisis</>,
      "app": "/apps/5eaf89c7d0d0442cb6bcab7f"
    }
  ],
}

const Authors = () => {
  return (
    <Template content={content} />
  )
};
export default Authors;
