import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "The Future of E-Commerce is Interactive Buying Guides",
  },
  "header": {
    "title": <>The Future of E-Commerce is <span>Interactive</span> Buying Guides</>,
    "description": "Explore AI-Enabled buying guides that assist consumers in making informed purchase decisions at every level, from broad recommendations to specific product configurations.",
    "app": "/apps/6578b9d36837722e73ead772"
  },
  "main": [
    {
      "title": "Macro Buying Guide",
      "description": "Offer broad product recommendation guides that are not tied to any specific vendor.",
      "app": "/apps/66d5ad696fca370c65f3dd40"
    },
    {
      "title": "Micro Buying Guide",
      "description": "Deliver vendor-specific guides to help customers narrow down to the exact product.",
      "app": "/apps/66d5adb06fca370c65f3dd8b"
    },
    {
      "title": "Nano Buying Guide",
      "description": "Provide detailed configuration guides to assist customers in selecting specific product options.",
      "app": "/apps/66d5ade86fca370c65f3ddd2"
    },
    {
      "title": "Interactive E-Commerce",
      "description": <><span>70%</span> increased buyer conversion with interactive content compared to static content</>,
      "youtube": "https://www.youtube.com/embed/YOvuO7TMILE"
    },
    {
      "title": "2 min Demo Video",
      "description": "Watch a quick 2 min video on how to create your first buying guide!",
      "youtube": "https://www.youtube.com/embed/YKjwE9tcSgc",
    }
  ],
}

const ECommerce = () => {
  return (
    <Template content={content} />
  )
};
export default ECommerce;
