import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Better Civic Engagement",
  },
  "header": {
    "title": <>Better Civic Engagement</>,
    "description": "Powerful interactive content to drive better civic engagement.",
    "app": "/apps/6403dd0a38a7cb0dc3ea916c"
  },
  "main": [
    {
      "title": "Voting Guide",
      "description": "The original static content and the converted interactive content",
      "apps": [
        "/apps/66ae4ed0afb8636dcb8ece61", "/colorado_informed-how-to-vote-in-colorado"],
      "cta": ""
    },
  ],
}

const CivicOrganizations = () => {
  return (
    <Template content={content} />
  )
};
export default CivicOrganizations;
