import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ConfigProvider } from "../../submodules/logictry_common/src/contexts/ConfigContext";
import Home from "../../containers/Home";
import DEFAULT_CONFIG from '../../submodules/logictry_common/src/config/AppConfig';
import SalesTeams from "../../containers/SalesTeams";
import MarketingTeams from "../../containers/MarketingTeams";
import SupportTeams from "../../containers/SupportTeams";
// import Solutions from "../../containers/Solutions";
// import SmallBusiness from "../../containers/SmallBusiness";
// import Platform from "../../containers/Platform";
import Company from "../../containers/Company";
// import ChannelPartners from "../../containers/Channel Partners";
// import ExpertPartners from "../../containers/Expert Partners";
import ExpertCommunities from "../../containers/ExpertCommunities";
import ECommerce from "../../containers/ECommerce";
import ArtificialIntelligence from "../../containers/ArtificialIntelligence";
import WorldLogicDay from "../../containers/WorldLogicDay";
import InteractiveContent from "../../containers/InteractiveContent";
import MakeBetterDecisionsFaster from "../../containers/MakeBetterDecisionsFaster";
import MakeLogicPossible from "../../containers/MakeLogicPossible";
// import Pricing from "../../containers/Pricing";
import Sitemap from '../../components/Navbar/sitemap.json';
import KnowledgeTransfer from "../../containers/KnowledgeTransfer";
import SmartFAQs from "../../containers/SmartFAQs";
import SalesPlaybooks from "../../containers/SalesPlaybooks";
import SalesConfigurators from "../../containers/SalesConfigurators";
import StandardOperatingProcedures from "../../containers/StandardOperatingProcedures";
import BuyingGuides from "../../containers/BuyingGuides";
import KnowledgeTeams from "../../containers/KnowledgeTeams";
import Chatbots from "../../containers/Chatbots";
import FullScreenApp from "../../components/FullScreenApp";
import EBooks from "../../containers/EBooks";
import LawFirms from "../../containers/LawFirms";
import CivicOrganizations from "../../containers/CivicOrganizations";
import DocumentGenerators from "../../containers/DocumentGenerators";
import ELearning from "../../containers/ELearning";
import Quizzes from "../../containers/Quizzes";
import Assessments from "../../containers/Assessments";
import Calculators from "../../containers/Calculators";
import DecisionTrees from "../../containers/DecisionTrees";
import EventPlanners from "../../containers/EventPlanners";
import Directories from "../../containers/Directories";
import ProductManuals from "../../containers/ProductManuals";
import RepairManuals from "../../containers/RepairManuals";
import Authors from "../../containers/Authors";
import WhitePapers from "../../containers/WhitePapers";
import LogicApp from "../../containers/LogicApp";
import LogicPlatform from "../../containers/LogicPlatform";
import NoCodePlatform from "../../containers/NoCodePlatform";
import SingleSourceOfTruth from "../../containers/SingleSourceOfTruth";
import HRHandbooks from "../../containers/HRHandbooks";
import ProductCatalogs from "../../containers/ProductCatalogs";
import SalesGuides from "../../containers/SalesGuides";
import WorldLogicForum from "../../containers/WorldLogicForum";
import Collections from "../../containers/Collections";
import FullScreenCollection from "../../components/FullscreenCollection";

const history = new Set();
const AppRouter = () => {
  // Wait to load until config received in production
  const location = useLocation()
  useEffect(() => {
    if (!history.has(window.history.state.key)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant" as ScrollBehavior,
      });
    }
    history.add(window.history.state.key);
  }, [location]);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1800}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
      <Routes key={window.location.href}>
        <Route path={Sitemap.InteractiveContent} element={<InteractiveContent />} />
        <Route path={Sitemap.ArtificialIntelligence} element={<ArtificialIntelligence />} />
        <Route path={Sitemap.NoCodePlatform} element={<NoCodePlatform />} />
        <Route path={Sitemap.SingleSourceOfTruth} element={<SingleSourceOfTruth />} />
        <Route path={Sitemap.Assessments} element={<Assessments />} />
        <Route path={Sitemap.BuyingGuides} element={<BuyingGuides />} />
        <Route path={Sitemap.Calculators} element={<Calculators />} />
        <Route path={Sitemap.Chatbots} element={<Chatbots />} />
        <Route path={Sitemap.Collections} element={<Collections />} />
        <Route path={Sitemap.DocumentGenerators} element={<DocumentGenerators />} />
        <Route path={Sitemap.DecisionTrees} element={<DecisionTrees />} />
        <Route path={Sitemap.Directories} element={<Directories />} />
        <Route path={Sitemap.EBooks} element={<EBooks />} />
        <Route path={Sitemap.ELearning} element={<ELearning />} />
        <Route path={Sitemap.EventPlanners} element={<EventPlanners />} />
        <Route path={Sitemap.HRHandbooks} element={<HRHandbooks />} />
        <Route path={Sitemap.ProductCatalogs} element={<ProductCatalogs />} />
        <Route path={Sitemap.ProductManuals} element={<ProductManuals />} />
        <Route path={Sitemap.Quizzes} element={<Quizzes />} />
        <Route path={Sitemap.RepairManuals} element={<RepairManuals />} />
        <Route path={Sitemap.SalesConfigurators} element={<SalesConfigurators />} />
        <Route path={Sitemap.SalesGuides} element={<SalesGuides />} />
        <Route path={Sitemap.SalesPlaybooks} element={<SalesPlaybooks />} />
        <Route path={Sitemap.SmartFAQs} element={<SmartFAQs />} />
        <Route path={Sitemap.StandardOperatingProcedures} element={<StandardOperatingProcedures />} />
        <Route path={Sitemap.WhitePapers} element={<WhitePapers />} />
        <Route path={Sitemap.KnowledgeTransfer} element={<KnowledgeTransfer />} />
        <Route path={Sitemap.SalesTeams} element={<SalesTeams />} />
        <Route path={Sitemap.MarketingTeams} element={<MarketingTeams />} />
        <Route path={Sitemap.SupportTeams} element={<SupportTeams />} />
        <Route path={Sitemap.KnowledgeTeams} element={<KnowledgeTeams />} />
        <Route path={Sitemap.Authors} element={<Authors />} />
        <Route path={Sitemap.CivicOrganizations} element={<CivicOrganizations />} />
        <Route path={Sitemap.ECommerce} element={<ECommerce />} />
        <Route path={Sitemap.ExpertCommunities} element={<ExpertCommunities />} />
        <Route path={Sitemap.LawFirms} element={<LawFirms />} />
        <Route path={Sitemap.Articles} element={<FullScreenCollection title="Articles" app="674e2f4ee65f10da50f2cbb6" />} />
        {/* <Route path={Sitemap.Articles} element={<Articles />} /> */}
        {/* <Route path={Sitemap.Articles + "/:id"} element={<Articles />} /> */}
        <Route path={Sitemap.CaseStudies} element={<FullScreenCollection title="Case Studies" app="674e2f6de65f10da50f2cbd7" />} />
        {/* <Route path={Sitemap.CaseStudies} element={<FullScreenApp title="Case Studies" app="5f610919ffcb101f8e64d95b" />} /> */}
        {/* <Route path={Sitemap.CaseStudies + "/:id"} element={<CaseStudies />} /> */}
        <Route path={Sitemap.News} element={<FullScreenCollection title="News" app="674e2faae65f10da50f2cc22" />} />
        <Route path={Sitemap.Resources} element={<FullScreenCollection title="Resources" app="674ef192453a6702ff950b0b" />} />
        {/* <Route path={Sitemap.News} element={<News />} />
        <Route path={Sitemap.News + "/:id"} element={<News />} /> */}
        {/* <Route path="/pricing" element={<Pricing />} /> */}
        <Route path={Sitemap.LogicApp} element={<LogicApp />} />
        <Route path={Sitemap.LogicPlatform} element={<LogicPlatform />} />
        <Route path={Sitemap.WorldLogicDay} element={<WorldLogicDay />} />
        <Route path={Sitemap.WorldLogicForum} element={<WorldLogicForum />} />
        <Route path={Sitemap.Company} element={<Company />} />
        <Route path={Sitemap.MakeLogicPossible} element={<MakeLogicPossible />} />
        <Route path={Sitemap.MakeBetterDecisionsFaster} element={<MakeBetterDecisionsFaster />} />
        {/* <Route path={Sitemap.FullScreenAssessment} element={<FullScreenApp title="Can we help?" app="66d0dfffe12117638c7aa840" />} /> */}
        <Route path="*" element={<Home />} />
      </Routes>
    </>
  );
};

const App = () => (
  <>
    <ConfigProvider defaultConfig={DEFAULT_CONFIG}>
      <Router>
        <AppRouter />
      </Router>
    </ConfigProvider>
    <iframe title={'66b25fede559423de19e7020'} style={{ position: 'fixed', bottom: 8, right: 8, height: 600, width: 400 }} src={`${window.location.hostname.endsWith('localhost') ? 'https://localhost' : 'https://logictry.com'}/apps/66b25fede559423de19e7020?showbutton=true&autoresize=false&showchatbot=true&showtoggle=true`}></iframe>
  </>
);

export default App;
