import React from "react";
import Template from "../../components/Template";

const content = {
  "meta": {
    "title": "Interactive Community Resources",
  },
  "header": {
    "title": "Interactive Community Resources",
    "description": <>Scale your community with <b>next generation interactive resources</b></>,
    "app": "/apps/62e9376aa8494044cd1943c4"
  },
  "main": [
    {
      "title": "Custom Branded Directories",
      "description": "Fiesta curated a directory of austin accelerators and incubators",
      "app": "/apps/62fcf9c4bfe1ab0e46b4d830"
    },
    {
      "title": "Voting Guides",
      "description": "Colorado Informed built interactive personalized voting instructions",
      "app": "/apps/633f4c1399524d0da3360029"
    },
    {
      "title": "Eligibility Guides",
      "description": "OmniSync built an eligibility guide for Federal Grants",
      "app": "/apps/6388c302468fa504b24a0d29"
    },
    {
      "title": "Document Generators",
      "description": "Y Combinator built a legal document generator for their FAST agreement",
      "app": "/apps/6386223025ea03095f80173a"
    },
    {
      "title": "Decision Guides",
      "description": "Fyli built a tool to help their community decide if a mastermind group is right",
      "app": "/apps/6320b4964171281d81c74bd7"
    },
    {
      "title": "Investor Matrix",
      "description": "Fyli built a tool to help their community decide if a mastermind group is right",
      "app": "/apps/642ee4383705a00dbdace397"
    },
  ],
}

const ExpertCommunities = () => {
  return (
    <Template content={content} />
  )
};
export default ExpertCommunities;
